<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <!--        <b-row class="justify-content-between">-->
          <b-col cols="3">
            <b-form-group
              label="사이트"
              label-for="branch-site"
            >
              <b-form-select
                id="branch-site"
                ref="branch-site"
                v-model="siteSelected"
                :options="authSite"
                class="font-small-3"
                style="color:rgb(229,166,48);"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="3"
          >
            <b-form-group
              label="총판"
              label-for="branch"
            >
              <b-form-select
                id="branch"
                ref="branch"
                v-model="branchSelected"
                :options="branchSelectOptions"
                class="font-small-3"
                style="color:rgb(229,166,48)"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
          >
            <b-input-group
              class="calendar-size font-small-3"
              style="padding-top: 1.5rem"
            >
              <b-form-datepicker
                v-model="periodFrom"
                class="align-self-center"
                boundary="window"
                locale="ko"
                placeholder="시작일"
                aria-controls="example-input"
                size="sm"
                :max="periodTo"
              />
              <div class="pl-1 pr-1">
                ~
              </div>
              <b-form-datepicker
                v-model="periodTo"
                class="align-self-center"
                boundary="window"
                locale="ko"
                placeholder="종료일"
                aria-controls="example-input"
                size="sm"
                :min="periodFrom"
              />
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="ml-1"
                @click="searchData"
              >
                검색
              </b-button>
            </b-input-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card
      no-body
      class="mb-0"
    >
      <div>
        <div class="d-flex justify-content-between">
          <div>
            <div
              class="text-info small pt-1"
              style="padding-left: 2rem"
            >
              Z: 이월되는 Z`의 값
            </div>
            <div
              class="text-info small"
              style="padding-left: 2rem"
            >
              Z`: 총판포함 하위캐쉬 + 총판포함 하위카지노캐쉬 + 총판포함 하위포인트
            </div>
          </div>
          <div class="d-flex justify-content-end align-items-center">
            <!-- 전체 펼치기 버튼 -->
            <b-button
              v-if="false"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-info"
              class="btn-icon mt-1"
              style="margin-right: 1rem"
              @click="expandAllRows"
            >
              전체 펼치기
            </b-button>

            <!-- 전체 접기 버튼 -->
            <b-button
              v-if="false"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-info"
              class="btn-icon mt-1"
              @click="collapseAllRows"
            >
              전체 접기
            </b-button>
            <b-form-checkbox
              v-model="isExpandCollapse"
              checked="false"
              class="custom-control-primary mt-1"
              style="margin-right: 2rem"
              name="check-button"
              switch
              @change="toggleExpandCollapse"
            >
              <span class="switch-icon-left">
                <feather-icon icon="CornerRightDownIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="CropIcon" />
              </span>
            </b-form-checkbox>
          </div>
        </div>
        <b-row class="m-1">
          <b-col>
            <b-table
              ref="branchDailyTable"
              responsive
              striped
              hover
              small
              show-empty
              empty-text="조회내역이 없습니다."
              thead-class="text-center"
              tbody-class="text-center"
              sort-by="baseDate"
              :sort-desc="false"
              :items="branchItems"
              :fields="branchGroupFields"
              :tbody-transition-props="transProps"
              @row-clicked="handleRowClick"
            >
              <template #thead-top>
                <b-tr>
                  <b-th
                    rowspan="3"
                    class="align-middle"
                    style="font-size: small; min-width: 10rem;"
                  >
                    총판명
                  </b-th>
                  <b-th
                    rowspan="3"
                    class="align-middle"
                    style="font-size: small;"
                  >
                    회원
                  </b-th>
                  <b-th
                    colspan="2"
                    class="align-middle"
                    style="font-size: small; "
                  >
                    현재머니
                  </b-th>
                  <b-th
                    colspan="2"
                    class="text-center align-middle"
                  >
                    입출금
                  </b-th>
                  <b-th
                    rowspan="3"
                    class="align-middle"
                    style="font-size: small; min-width: 5rem; background-color: #21315a;"
                  >
                    충-환<br>
                    <span style="font-size: xx-small;">(C=A-B)</span>
                  </b-th>
                  <b-th
                    colspan="3"
                    class="text-center align-middle"
                  >
                    루징(D)
                  </b-th>
                  <b-th
                    rowspan="3"
                    class="text-center align-middle"
                    style="background-color: #21315a;"
                  >
                    루징금<br>합계<br>
                    <span style="font-size: xx-small;"> (D=S+T)</span>
                  </b-th>
                  <b-th
                    rowspan="3"
                    class="align-middle"
                    style="font-size: small; min-width: 5rem;"
                  >
                    총판<br> 누적 루징금<br>
                    <span style="font-size: xx-small;"> (E=D+D') </span>
                  </b-th>
                  <b-th
                    colspan="9"
                    class="text-center align-middle"
                  >
                    롤링
                  </b-th>
                  <b-th
                    rowspan="3"
                    class="align-middle"
                    style="font-size: small; min-width: 5rem; background-color: #21315a;"
                  >
                    롤링금 합계<br>
                    <span style="font-size: xx-small;">(F=O+P+Q)</span>
                  </b-th>
                  <b-th
                    rowspan="3"
                    class="align-middle"
                    style="font-size: small; min-width: 5rem;"
                  >
                    총판<br>출금액<br>
                    <span style="font-size: xx-small;"> (G) </span>
                  </b-th>
                  <b-th
                    rowspan="3"
                    class="align-middle"
                    style="font-size: small; min-width: 5rem;"
                  >
                    총판<br>출금가능<br>
                    <span style="font-size: xx-small;"> (H=E+F-G) </span>
                  </b-th>
                </b-tr>
                <b-tr>
                  <b-th
                    rowspan="2"
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    캐쉬
                  </b-th>
                  <b-th
                    rowspan="2"
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    포인트
                  </b-th>
                  <b-th
                    rowspan="2"
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    충전<br>
                    <span style="font-size: xx-small;">(A)</span>
                  </b-th>
                  <b-th
                    rowspan="2"
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    환전<br>
                    <span style="font-size: xx-small;">(B)</span>
                  </b-th>
                  <b-th
                    rowspan="2"
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    요율%
                    <span style="font-size: xx-small;">(R)</span>
                  </b-th>
                  <b-th
                    rowspan="2"
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    자신
                    <span style="font-size: xx-small;">(S)</span>
                  </b-th>
                  <b-th
                    rowspan="2"
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    하위
                    <span style="font-size: xx-small;">(T)</span>
                  </b-th>
                  <b-th
                    colspan="3"
                    class="text-center align-middle"
                  >
                    스포츠
                  </b-th>
                  <b-th
                    colspan="3"
                    class="text-center align-middle"
                  >
                    카지노
                  </b-th>
                  <b-th
                    colspan="3"
                    class="text-center align-middle"
                  >
                    슬롯
                  </b-th>

                </b-tr>
                <b-tr>
                  <b-th
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    배팅금
                    <span style="font-size: xx-small;">(I)</span>
                  </b-th>
                  <b-th
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    요율%
                    <span style="font-size: xx-small;">(L)</span>
                  </b-th>
                  <b-th
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    롤링금
                    <span style="font-size: xx-small;">(O=I*L)</span>
                  </b-th>

                  <b-th
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    배팅금
                    <span style="font-size: xx-small;">(J)</span>
                  </b-th>
                  <b-th
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    요율%
                    <span style="font-size: xx-small;">(M)</span>
                  </b-th>

                  <b-th
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    롤링금
                    <span style="font-size: xx-small;">(P=J*M)</span>
                  </b-th>
                  <b-th
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    배팅금
                    <span style="font-size: xx-small;">(K)</span>
                  </b-th>
                  <b-th
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    요율%
                    <span style="font-size: xx-small;">(N)</span>
                  </b-th>
                  <b-th
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    롤링금
                    <span style="font-size: xx-small;">(Q=K*N)</span>
                  </b-th>
                </b-tr>
              </template>
              <template #cell(branch)="data">
                <div
                  class="d-flex align-items-center"
                  :style="{ paddingLeft: `${(data.item.level || 0) * 20}px` }"
                >
                  <!-- 확장/축소 아이콘 -->
                  <div
                    class="me-2"
                    style="width: 20px; text-align: center;"
                  >
                    <feather-icon
                      v-if="Array.isArray(data.item.children) && data.item.children.length > 0"
                      :icon="data.item.isExpanded ? 'ChevronDownIcon' : 'ChevronRightIcon'"
                      size="14"
                    />
                  </div>

                  <!-- 브랜치 이름 -->
                  <div
                    style="min-width: 7rem;"
                  >
                    <template>
                      <div
                        class="d-flex align-items-center "
                      >
                        <b-avatar
                          class="cursor-pointer"
                          style="margin-right: 5px; "
                          size="20"
                          :src="data.item.role"
                          :variant="`${resolveUserRoleVariant(data.item.role)}`"
                          @click.stop="handleUserDetail(data.item)"
                        />
                        <!-- :text="avatarText(data.item.role)" -->
                        <span
                          class="text-success font-weight-bold text-nowrap cursor-pointer"
                          style="text-align: left !important; text-decoration: underline; text-underline-offset: 5px;"
                          @click.stop="handleUserDetail(data.item)"
                        >
                          {{ data.item.nickname }}
                        </span>
                      </div>
                    </template>
                  </div>
                </div>
              </template>
              <template #cell(user)="data">
                <div class="d-flex align-items-start justify-content-between">
                  <div
                    class="d-flex flex-column align-items-center"
                    style="border-right: 1px solid #3b4253; padding-right: 8px;"
                  >
                    <div class="d-flex align-items-center">
                      <b-avatar
                        size="14"
                        src="branch"
                        :variant="resolveUserRoleVariant('branch')"
                        class=""
                      />
                      <span
                        style="display: inline-block; text-align: right; margin-left: 3px;"
                      >
                        {{ data.item.lowerRoleCount ? (JSON.parse(data.item.lowerRoleCount).branch || 0) : 0 }}
                      </span>
                    </div>
                    <div class="d-flex align-items-center">
                      <b-avatar
                        size="14"
                        src="user"
                        :variant="resolveUserRoleVariant('user')"
                        class=""
                      />
                      <span
                        style="display: inline-block;  text-align: right; margin-left: 3px;"
                      >
                        {{ data.item.lowerRoleCount ? (JSON.parse(data.item.lowerRoleCount).user || 0) : 0 }}
                      </span>
                    </div>
                  </div>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-icon"
                    size="sm"
                    style="align-self: center; "
                    @click="handleShowUsers(data.item)"
                  >
                    <feather-icon
                      icon="UsersIcon"
                      size="12"
                    />
                  </b-button>
                </div>
              </template>
              <template #cell(cash)="data">
                <div
                  v-b-tooltip.hover.bottom.html="'캐쉬: ' + Number(data.item.cash).toLocaleString() + ' (+' + Number(data.item.lowerCash).toLocaleString() + ')'"
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.sumCash).toLocaleString() }}
                </div>
              </template>
              <template #cell(point)="data">
                <div
                  v-b-tooltip.hover.bottom.html="'포인트: ' + Number(data.item.point).toLocaleString() + ' (+' + Number(data.item.lowerPoint).toLocaleString() + ')'"
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.sumPoint).toLocaleString() }}
                </div>
              </template>

              <template #cell(deposit)="data">
                <div
                  v-b-tooltip.hover.bottom.html="Number(data.item.deposit).toLocaleString() + '(+' + Number(data.item.lowerDeposit).toLocaleString() + ')'"
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.sumDeposit).toLocaleString() }}
                </div>
              </template>
              <template #cell(exchange)="data">
                <div
                  v-b-tooltip.hover.bottom.html="Number(data.item.exchange).toLocaleString() + '(+' + Number(data.item.lowerExchange).toLocaleString() + ')'"
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.sumExchange).toLocaleString() }}
                </div>
              </template>
              <template #cell(balance)="data">
                <div
                  class="text-right"
                  style="font-size: small; min-width: 6rem; "
                >
                  <!-- :style="{
                    fontSize: 'small',
                    minWidth: '6rem',
                    textDecoration: getTotalBalance(data).isActive ? 'underline' : 'none',
                    textUnderlineOffset: getTotalBalance(data).isActive ? '5px' : 'initial'
                  }"
                  @click="getTotalBalance(data).isActive && fncFetchBalanceDepositExchange(data)"
                   -->
                  {{ getTotalBalance(data).total.toLocaleString() }}
                </div>
              </template>

              <template #cell(sumLosing)="data">
                <div
                  v-b-tooltip.hover.bottom.html="Number(data.item.losing).toLocaleString() + '(+' + Number(data.item.accLowerLosing).toLocaleString() + ')'"
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.sumLosing).toLocaleString() }}
                </div>
              </template>
              <template #cell(accLosing)="data">
                <div
                  v-b-tooltip.hover.bottom.html="Number(data.item.preAccLosing).toLocaleString() + '(+' + Number(data.item.sumLosing).toLocaleString() + ')'"
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.accLosing).toLocaleString() }}
                </div>
              </template>
              <template #cell(accLowerLosing)="data">
                <div
                  v-if="Number(data.item.accLowerLosing) > 0"
                  :style="{
                    fontSize: 'small',
                    minWidth: '6rem',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    textUnderlineOffset: '5px'
                  }"
                  @click.stop="handleShowLowerLosing(data.item)"
                >
                  {{ Number(data.item.accLowerLosing).toLocaleString() }}
                </div>
                <div
                  v-else
                >
                  {{ Number(data.item.accLowerLosing).toLocaleString() }}
                </div>
              </template>

              <template #cell(sportBettingAmount)="data">
                <div
                  v-b-tooltip.hover.bottom.html="Number(data.item.sportBettingAmount).toLocaleString() + '(+' + Number(data.item.lowerSportBettingAmount).toLocaleString() + ')'"
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.sumSportBettingAmount).toLocaleString() }}
                </div>
              </template>
              <template #cell(sportRollingRate)="data">
                <div class="text-center">
                  {{ data.item.sportRollingRate || '-' }}
                </div>
              </template>
              <template #cell(sportRollingAmount)="data">
                <div
                  v-b-tooltip.hover.bottom.html="Number(data.item.sportRollingAmount).toLocaleString() + '(+' + Number(data.item.lowerSportRollingAmount).toLocaleString() + ')'"
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.sumSportRollingAmount).toLocaleString() }}
                </div>
              </template>
              <template #cell(casinoBettingAmount)="data">
                <div
                  v-b-tooltip.hover.bottom.html="Number(data.item.casinoBettingAmount).toLocaleString() + '(+' + Number(data.item.lowerCasinoBettingAmount).toLocaleString() + ')'"
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.sumCasinoBettingAmount).toLocaleString() }}
                </div>
              </template>
              <template #cell(casinoRollingRate)="data">
                <div class="text-center">
                  {{ data.item.casinoRollingRate || '-' }}
                </div>
              </template>
              <template #cell(casinoRollingAmount)="data">
                <div
                  v-b-tooltip.hover.bottom.html="Number(data.item.casinoRollingAmount).toLocaleString() + '(+' + Number(data.item.lowerCasinoRollingAmount).toLocaleString() + ')'"
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.sumCasinoRollingAmount).toLocaleString() }}
                </div>
              </template>
              <template #cell(slotBettingAmount)="data">
                <div
                  v-b-tooltip.hover.bottom.html="Number(data.item.slotBettingAmount).toLocaleString() + '(+' + Number(data.item.lowerSlotBettingAmount).toLocaleString() + ')'"
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.sumSlotBettingAmount).toLocaleString() }}
                </div>
              </template>
              <template #cell(slotRollingRate)="data">
                <div class="text-center">
                  {{ data.item.slotRollingRate || '-' }}
                </div>
              </template>
              <template #cell(slotRollingAmount)="data">
                <div
                  v-b-tooltip.hover.bottom.html="Number(data.item.slotRollingAmount).toLocaleString() + '(+' + Number(data.item.lowerSlotRollingAmount).toLocaleString() + ')'"
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.sumSlotRollingAmount).toLocaleString() }}
                </div>
              </template>
              <template #cell(rollingAmount)="data">
                <div
                  class="text-right"
                  :style="{
                    fontSize: 'small',
                    minWidth: '6rem',
                    textDecoration: data.item.rollingAmount > 0 ? 'underline' : 'none',
                    textUnderlineOffset: data.item.rollingAmount > 0 ? '5px' : 'initial'
                  }"
                  @click="fncFetchBalanceRolling(data)"
                >
                  {{ data.item.rollingAmount.toLocaleString() }}
                </div>
              </template>
              <template #cell(type)="data">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="btn-icon"
                  size="sm"
                  :variant="data.item.type === 'ok' ? 'secondary' : 'primary'"
                  :disabled="data.item.type === 'ok'"
                  @click="updateStatusCommission(data.item)"
                >
                  <feather-icon
                    :icon="data.item.type === 'ok' ? 'CheckIcon' : 'DollarSignIcon'"
                    size="14"
                  />
                </b-button>
              </template>
              <template
                v-if="branchItems.length > 0"
                slot="bottom-row"
              >
                <td
                  colspan="4"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  Total
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('sumDeposit')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('sumExchange')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('sumDeposit')-getTotal('sumExchange')).toLocaleString() }}
                </td>
                <td
                  style="background-color: #24233a; color:rgb(196,186,138)"
                />
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('losing')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('accLowerLosing')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('sumLosing')).toLocaleString() }}
                </td><td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('accLosing')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('sumSportBettingAmount')).toLocaleString() }}
                </td>
                <td
                  style="background-color: #24233a; color:rgb(196,186,138)"
                />
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('sumSportRollingAmount')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('sumCasinoBettingAmount')).toLocaleString() }}
                </td>
                <td
                  style="background-color: #24233a; color:rgb(196,186,138)"
                />
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('sumCasinoRollingAmount')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('sumSlotBettingAmount')).toLocaleString() }}
                </td>
                <td
                  style="background-color: #24233a; color:rgb(196,186,138)"
                />
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('sumSlotRollingAmount')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('rollingAmount')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('withdrawnAmount')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Math.abs(Number(getTotal('withdrawableAmount'))).toLocaleString() }}
                </td>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BTable,
  BTr,
  BTh,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BFormSelect,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BBadge,
  BFormDatepicker,
  BCardText,
  BFormTextarea,
  VBTooltip,
  BMedia,
  BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'

import { createNamespacedHelpers } from 'vuex'
import {
  FETCH_BRANCH_SELECT_OPTIONS,
  FETCH_BRANCH_BALANCE_GROUP,
  FETCH_BRANCH_ORGANIZATION_CHART,
  FETCH_BRANCH_ORGANIZATION_CHART_SUB,
} from '@/store/branch/action'

import * as moment from 'moment-timezone'

moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'
const fmt2 = 'YYYYMMDD'
const fmt3 = 'YYYY-MM-DD'

const branchStore = createNamespacedHelpers('branchStore')
const settingsStore = createNamespacedHelpers('settingsStore')

export default {
  name: 'BranchCalculateGroup',

  components: {
    BCard,
    BCardBody,
    BTable,
    BTr,
    BTh,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BPagination,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BBadge,
    BFormDatepicker,
    BCardText,
    BFormTextarea,
    BMedia,
    BAvatar,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      currentURL: window.location.href,
      componentName: this.$options.name,
      branchSelected: null,

      // cashOutBranchName: JSON.parse(localStorage.getItem('userData')).nickname,
      cashOutBranchDate: moment(new Date()).subtract(1, 'days').format(fmt3),
      cashOutBranchAvailable: 0,
      cashOutBranchAmount: 0,
      cashOutBranchComment: '',
      branchItems: [],
      branchGroupFields: [
        { key: 'branch', thClass: 'd-none ', tdClass: 'text-left' },
        { key: 'user', thClass: 'd-none', tdClass: 'text-center' },
        { key: 'cash', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' }, // 현재머니(캐쉬)
        { key: 'point', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' }, // 현재머니(포인트)
        { key: 'deposit', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' }, // 입출금(출금)
        { key: 'exchange', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' }, // 입출금(환전)
        { key: 'balance', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' }, // 충환
        { key: 'rate', thClass: 'd-none' },
        { key: 'losing', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'accLowerLosing', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'sumLosing', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' }, // 루징금합계
        { key: 'accLosing', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' }, // 총판 누적 루징금
        { key: 'sportBettingAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'sportRollingRate', thClass: 'd-none', tdClass: 'text-right' },
        { key: 'sportRollingAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'casinoBettingAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'casinoRollingRate', thClass: 'd-none', tdClass: 'text-right' },
        { key: 'casinoRollingAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'slotBettingAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'slotRollingRate', thClass: 'd-none', tdClass: 'text-right' },
        { key: 'slotRollingAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'rollingAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'withdrawnAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'withdrawableAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
      ],
      periodFrom: '',
      periodTo: '',

      transProps: {
        name: 'fade',
        mode: 'in-out',
      },

      isExpandCollapse: false,
    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected: {
      get() {
        return this.$store.state.rootStore.siteSelected
      },
      set(value) {
        this.$store.commit('rootStore/SET_SITE_SELECTED', value)
        this.init()
      },
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    ...branchStore.mapGetters({
      fetchBranchSelectOptions: 'fetchBranchSelectOptions',
      fetchBranchBalanceGroup: 'fetchBranchBalanceGroup',
      fetchBranchOrganizationChart: 'fetchBranchOrganizationChart',
      fetchBranchOrganizationChartSub: 'fetchBranchOrganizationChartSub',
    }),
    branchSelectOptions() {
      const resultData = this.fetchBranchSelectOptions.map(option => ({
        value: option,
        text: option.status === 'active'
          ? `[${option.role}] ${option.nickname}`
          : `[${option.role}] ${option.nickname} (${option.status})`,
        selected: option.userid === this.userData.userid,
      }))
      this.branchSelected = this.fetchBranchSelectOptions.find(option => option.userid === this.userData.userid)
      return resultData
    },
    filteredBalanceTreeGroup() {
      // 계층구조 생성
      const buildHierarchy = data => {
        const map = new Map()
        const node = []

        // 모든 항목을 Map에 저장하고 초기 level을 설정
        data.forEach(item => {
          map.set(item.nickname, { ...item, level: 0 })
        })

        // 계층구조 생성
        data.forEach(item => {
          if (item.recommendNickname && map.has(item.recommendNickname)) {
            const parent = map.get(item.recommendNickname)

            // 부모에 children 속성이 없으면 추가
            if (!parent.children) {
              parent.children = []
            }

            const child = map.get(item.nickname)
            child.level = parent.level + 1 // 자식의 level을 1증가

            parent.children.push(child) // 부모 노드의 children 배열에 추가
          } else {
            node.push(map.get(item.nickname)) // 최상위 노드
          }
        })

        return node
      }
      const resultData = buildHierarchy(this.fetchBranchBalanceGroup)

      return resultData
    },
    // _filteredBalanceTreeGroup() {
    //   function countRolesAndAddToData(data) {
    //     function traverse(node) {
    //       const roleCounts = {}

    //       // children을 순회하며 role별 개수 집계 (현재 노드의 children만 집계)
    //       if (node.children && node.children.length > 0) {
    //         node.children.forEach(child => {
    //           const { role } = child
    //           roleCounts[role] = (roleCounts[role] || 0) + 1

    //           // 자식 노드를 재귀적으로 탐색
    //           traverse(child)
    //         })
    //       }

    //       // 현재 노드에 roleCount 속성 추가 (현재 노드의 children만 집계)
    //       node.roleCount = { ...roleCounts }

    //       return roleCounts
    //     }

    //     // 최상위 데이터에서 시작
    //     data.forEach(traverse)
    //     return data
    //   }
    //   function removeUsersFromChildren(data) {
    //     function traverseAndFilter(node) {
    //       if (node.children && node.children.length > 0) {
    //         // role이 "user"가 아닌 것만 남기고 필터링
    //         node.children = node.children.filter(child => child.role !== 'user')

    //         // 필터링된 자식들에 대해 재귀 호출
    //         node.children.forEach(traverseAndFilter)

    //         // children의 길이가 0이면 children 속성 제거
    //         if (node.children.length === 0) {
    //           delete node.children
    //         }
    //       }
    //     }

    //     // 최상위 데이터에서 시작
    //     data.forEach(traverseAndFilter)
    //     return data
    //   }
    //   const processedData = countRolesAndAddToData(this.fetchBranchOrganizationChartSub)

    //   const fetchSiteBranch = removeUsersFromChildren(processedData)
    //   console.log('fetchSiteBranch', fetchSiteBranch)

    //   const branchMap = this.fetchBranchBalanceGroup.reduce((acc, branch) => {
    //     acc[branch.branch] = branch // branch를 key로 설정
    //     return acc
    //   }, {})

    //   const mergeBranchData = (item, level = 0) => {
    //     // `branch`에 매칭되는 데이터 가져오기
    //     const branchData = branchMap[(item.role === 'admin' ? 'admin' : item.nickname)] || {}

    //     // children 처리: 재귀 호출
    //     const children = Array.isArray(item.children) && item.children.length > 0
    //       ? item.children.map(child => mergeBranchData(child, level + 1))
    //       : undefined

    //     // 병합된 결과 반환
    //     return {
    //       site: item.site,
    //       userid: item.userid,
    //       username: item.username,
    //       nickname: item.nickname,
    //       recommendNickname: item.recommendNickname,
    //       role: item.role,
    //       status: item.status,
    //       authSite: item.authSite,
    //       level, // 계층 정보 추가
    //       ...branchData, // 매칭된 branch 데이터를 병합
    //       ...(item.roleCount && { roleCount: item.roleCount }), // roleCount가 있을 경우만 추가
    //       ...(children && { children }), // children이 존재하면 추가
    //     }
    //   }

    //   // branch 데이터를 Map 구조로 변환

    //   // fetchSiteBranch 재귀 탐색 및 병합
    //   const result = fetchSiteBranch.map(item => mergeBranchData(item))

    //   console.log(result)
    //   return result
    // },
  },
  watch: {
    branchSelected: {
      handler() {
        this.fetchData()
      },
      immediate: false,
      deep: false,
    },
    periodFrom: {
      handler() {
        this.fetchData()
      },
      immediate: false,
      deep: false,
    },
    periodTo: {
      handler() {
        this.fetchData()
      },
      immediate: false,
      deep: false,
    },
  },
  created() {
    // console.log(this.componentName, 'created()')
    this.init()
  },
  mounted() {
    // console.log(this.componentName, 'mounted()')
  },
  methods: {
    ...branchStore.mapActions({
      $fetchBranchSelectOptions: FETCH_BRANCH_SELECT_OPTIONS,
      $fetchBranchBalanceGroup: FETCH_BRANCH_BALANCE_GROUP,
      $fetchBranchOrganizationChart: FETCH_BRANCH_ORGANIZATION_CHART,
      $fetchBranchOrganizationChartSub: FETCH_BRANCH_ORGANIZATION_CHART_SUB,
    }),
    async init() {
      this.periodFrom = moment().startOf('month').format(fmt1) // 이달의 첫 번째 날
      this.periodTo = moment().endOf('day').format(fmt1) // 오늘 날짜

      await this.$fetchBranchSelectOptions({
        site: this.siteSelected,
        userid: this.userData.userid,
      })
      await this.fetchData()
    },
    searchData() {
      this.fetchData()
    },
    async fetchData() {
      if (!this.branchSelected) return false
      await this.$fetchBranchBalanceGroup({
        site: this.siteSelected,
        userid: this.branchSelected.userid,
        fromAt: this.periodFrom,
        toAt: this.periodTo,
      })
      // await this.$fetchBranchOrganizationChart({
      //   site: this.siteSelected,
      //   userid: this.branchSelected.userid,
      // })
      // await this.$fetchBranchOrganizationChartSub({
      //   site: this.siteSelected,
      //   userid: this.branchSelected.userid,
      // })
      this.branchItems = this.filteredBalanceTreeGroup
      this.expandAllRows(1) // 1단계만 펼친 상태로 설정
    },

    handleUserDetail(item) {
      console.log('@@@', item)
      // 기존 창에서 열기
      // localStorage.setItem('userView', JSON.stringify({ userid: item.userid, site: item.site, nickname: item.nickname }))
      // router.push({ name: 'apps-user-view' })

      // 새로운 창에서 열기
      // URL 쿼리 파라미터로 데이터를 전달
      const queryParams = {
        userid: item.userid,
        site: item.site,
        nickname: item.nickname,
      }
      const url = this.$router.resolve({
        name: 'apps-user-view',
        query: queryParams,
      }).href
      // 새로 고유한 이름을 가진 팝업 창을 열기 위해 현재 시간 사용
      const popupName = `userViewPopup_${new Date().getTime()}`
      const popupOptions = 'width=1800,height=1250,scrollbars=yes,resizable=yes' // 원하는 옵션 설정
      window.open(url, popupName, popupOptions) // 매번 다른 이름으로 팝업 창 열기
    },
    handleShowUsers(item) {
      console.log('@@@', item)
      // 새로운 창에서 열기
      // URL 쿼리 파라미터로 데이터를 전달
      const queryParams = {
        // baseDate: item.baseDate,
        userid: item.userid,
        fromAt: this.periodFrom,
        toAt: this.periodTo,
      }
      const url = this.$router.resolve({
        name: 'branch-users',
        query: queryParams,
      }).href

      // 새로 고유한 이름을 가진 팝업 창을 열기 위해 현재 시간 사용
      const popupName = `userViewPopup_${new Date().getTime()}`

      const screenWidth = window.innerWidth || document.documentElement.clientWidth || screen.width
      const screenHeight = window.innerHeight || document.documentElement.clientHeight || screen.height

      // 창 크기 계산 (너비 70%, 높이 60%)
      const popupWidth = Math.floor(screenWidth * 0.7)
      const popupHeight = Math.floor(screenHeight * 0.6)

      // 창 위치 계산 (화면 중앙 정렬)
      const left = Math.floor((screenWidth - popupWidth) / 2 + window.screenX)
      const top = Math.floor((screenHeight - popupHeight) / 2 + window.screenY)

      // 새 창 옵션 설정
      const popupOptions = `width=${popupWidth},height=${popupHeight},left=${left},top=${top},scrollbars=yes,resizable=yes`

      window.open(url, popupName, popupOptions)
    },

    toggleExpandCollapse() {
      if (this.isExpandCollapse) {
        this.expandAllRows()
      } else {
        this.collapseAllRows()
      }
    },

    // 펼치기/접기
    handleRowClick(item, index) {
      // console.log('handleRowClick:', item, index)
      if (item.children && item.children.length > 0) {
        if (!item.isExpanded) {
          this.expandRow(index, item)
        } else {
          this.collapseRow(index, item)
        }
        this.$set(this.branchItems, index, {
          ...item,
          isExpanded: !item.isExpanded,
        })
      }
    },

    expandRow(index, item) {
      if (Array.isArray(item.children) && item.children.length > 0) {
        const childrenWithLevel = item.children.map(child => ({
          ...child,
          // level: (item.level || 0) + 1,
          // isExpanded: false,
        }))
        this.branchItems.splice(index + 1, 0, ...childrenWithLevel)
      }
    },
    collapseRow(index, item) {
      const startLevel = item.level || 0
      let endIndex = index + 1

      while (endIndex < this.branchItems.length && (this.branchItems[endIndex].level || 0) > startLevel) {
        endIndex += 1
      }

      this.branchItems.splice(index + 1, endIndex - index - 1)
    },
    // 전체 펼치기
    expandAllRows(maxLevel = Infinity) {
      const recursivelyExpand = (items, currentLevel = 0) => items.flatMap(item => {
        if (item.isExpanded || !Array.isArray(item.children) || item.children.length === 0 || currentLevel >= maxLevel) {
          return item // 현재 항목을 그대로 반환
        }

        // 현재 항목을 펼치기
        const expandedItem = { ...item, isExpanded: true }

        // 자식 항목이 있는 경우 재귀적으로 처리
        if (Array.isArray(item.children) && item.children.length > 0) {
          return [
            expandedItem,
            ...recursivelyExpand(item.children, currentLevel + 1),
          ]
        }

        return expandedItem // 자식이 없는 경우 현재 항목만 반환
      })

      // 최상위 항목부터 시작
      this.branchItems = recursivelyExpand(this.branchItems)
    },

    // 전체 접기
    collapseAllRows() {
      const updatedItems = []
      this.branchItems.forEach(item => {
        if (item.level === 0) {
          updatedItems.push({ ...item, isExpanded: false })
        }
      })
      this.branchItems = updatedItems
    },

    /* [formatter] */
    formatNumberWithCommas(value) {
      if (value == null) {
        return ''
      }
      return parseInt(value, 10).toLocaleString()
    },
    getTotalBalance(data) {
      const deposit = Number(data.item.deposit) || 0
      const lowerDeposit = Number(data.item.lowerDeposit) || 0
      const exchange = Number(data.item.exchange) || 0
      const lowerExchange = Number(data.item.lowerExchange) || 0

      // 계산된 잔액
      const total = (deposit + lowerDeposit) - (exchange + lowerExchange)

      // isActive: 4개 항목 중 하나라도 0 이상이면 true, 모두 0이면 false
      const isActive = deposit > 0 || lowerDeposit > 0 || exchange > 0 || lowerExchange > 0

      return {
        total,
        isActive,
      }
    },
    getDateWithDash(value) {
      if (value == null) {
        return ''
      }
      return moment(value, 'YYYYMMDDHHmmss').format(fmt3)
    },
    getTotal(field) {
      return this.branchItems.reduce((acc, item) => acc + item[field], 0)
    },

    resolveUserRoleVariant(role) {
      if (role === 'user') return 'secondary'
      if (role === 'branch') return 'warning'
      if (role === 'admin') return 'primary'
      if (role === 'godfather') return 'danger'
      return 'secondary'
    },
  },
}

</script>

<style scoped>

.b-table > thead > tr > th {
  border-right: solid 1.5px #3b4253 !important;
  border-bottom: solid 1.5px #3b4253 !important;
  min-width: 6rem;
  /* white-space: no-wrap !important; */
}
.b-table > thead > tr > th:last-child {
  border-right: none !important;
}

.per-page-selector {
  width: 90px;
}
.category-select {
  width: 80px;
}
.b-table .table > thead > tr > th {
  white-space: pre-wrap !important;
}
</style>
